import { yupResolver } from "@hookform/resolvers/yup";
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { submitFormToHubspot } from "../../utils/submitFormToHubspot";
import {
  BasicElementsContainer,
  ErrorMessage,
  FormContainer,
  FormLabel,
  FormTitle,
  InputWrapper,
  PrivacyDisclaimer,
  StyledInput,
  SubmitButton,
  StyledSelect,
  StyledSelectWrapper,
  FormHubspotWrapper
} from "../shared/FormStyles";
import { Link } from "gatsby";

const QuoteForm = () => {

  useEffect(() => {
    const script = document.createElement('script');
    script.src = '//js.hsforms.net/forms/embed/v2.js';
    document.head.appendChild(script);

    script.onload = () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          region: "na1",
          portalId: "6034553",
          formId: "ddab88b0-d039-42f4-bba1-84d32152c4b9",
          target: "#hubspotForm",
        });
      }
    };

    return () => {
      document.head.removeChild(script);
    }
  }, []);

  return (
    <FormHubspotWrapper>
      <div id="hubspotForm" ></div>
    </FormHubspotWrapper>
  );
};

export default QuoteForm;
