import React from "react";
import QuoteForm from "../components/basicForm/QuoteForm";
import Layout from "../components/Layout";
import { PrivacyDisclaimer } from "../components/shared/FormStyles";
import BasicFormTemplate from "../templates/BasicFormTemplate";

const seo = {
  title: "Quotation",
  description:
    "Request a quotation for The PLX-Benchtop - a mechanical testing device that generates stress/strain data in just 3 minutes. ",
};

const title = `Request a quote`;
const byline = `Please fill in our contact form and our team will be in touch shortly to discuss our pricing options.`;

const RequestAQuote = () => {
  return (
    <Layout seo={seo} backgroundColorUnderneath="white">
      <BasicFormTemplate title={title} byline={byline}>
        <QuoteForm />
        <PrivacyDisclaimer mobile />
      </BasicFormTemplate>
    </Layout>
  );
};

export default RequestAQuote;
